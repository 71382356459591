import { useState } from "react";
import { Form, Input, message } from "antd";
import { At, Lock, Eye, EyeSlash } from "react-bootstrap-icons";
import { Alert } from "antd";
import MySpin from "../components/Ui/Spin";
import useAuth from "../hooks/useAuth";
import { authApi } from "../store/apis/auth";
import VerificationCode from "../components/forms/VerificationCode";
interface formFields {
  email: string;
  password: string;
}
enum forms {
  login = "login",
  forgetpassword = "forgetpassword",
  verfiycode = "verfiycode",
}
function Login() {
  const [Login, { error, isLoading }] = authApi.useLoginMutation({});
  const [SendEmailMethod] = authApi.useChangePasswordMutation();
  const { Login: loginAuth } = useAuth();

  const [form] = Form.useForm<formFields>();
  const [currentForm, setCurrentForm] = useState(forms.login);
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async ({ email, password }) => {
        await Login({ email, password })
          .unwrap()
          .then((res) => {
              if (res.shouldVerfie) setCurrentForm(forms.verfiycode);
            else  loginAuth(res); 
          });
      })
      .catch(() => {});
  };

  const ForgetPasswordToggle = () => {
    form.resetFields();
    setCurrentForm(forms.forgetpassword);
  };

  const handleAnnulerToggle = () => {
    setCurrentForm(forms.login);
  };

  const sendEmail = () => {
    form.validateFields(["email"]).then(({ email }) => {
      SendEmailMethod({ email });
      message.success("message a été envoyé avec success");
    });
  };
  if (currentForm === forms.verfiycode)
    return (
      <div className="from__center">
        <VerificationCode email={form.getFieldValue("email")} />
      </div>
    );

  if (currentForm === forms.forgetpassword)
    return (
      <div className="from__center">
        <Form className="Form" form={form} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "email est obligatoire",
              },
            ]}
          >
            <Input placeholder="Email" className="h-40 w-300" prefix={<At />} />
          </Form.Item>
          <button type="submit" className="btn__form" onClick={sendEmail}>
            Envoyer un e-mail de récupération
          </button>
          <p
            className="mt-2"
            style={{ textAlign: "center", cursor: "pointer", color: "#1CBFFF" }}
            onClick={handleAnnulerToggle}
          >
            Annuler
          </p>
        </Form>
      </div>
    );
  return isLoading ? (
    <MySpin />
  ) : (
    <div className="from__center">
      <Form className="Form " form={form} layout="vertical">
        {error ? (
          <Alert
            message={"status" in error ? error.data.message : undefined}
            type="error"
            className="w-300"
            showIcon
          />
        ) : null}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, type: "email", message: "email est obligatoire" },
          ]}
        >
          <Input placeholder="Email" className="h-40 w-300" prefix={<At />} />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: "Mot de passe est obligatoire" }]}
        >
          <Input.Password
            prefix={<Lock />}
            placeholder="password"
            className="h-40 w-300"
            iconRender={(visible) => (visible ? <Eye /> : <EyeSlash />)}
          />
        </Form.Item>
        <button type="submit" className="btn__form" onClick={handleSubmit}>
          Login
        </button>
        <p
          className="mt-2"
          style={{ textAlign: "center", cursor: "pointer", color: "#1CBFFF" }}
          onClick={ForgetPasswordToggle}
        >
          Rénitialiser le mot de passe
        </p>
      </Form>
    </div>
  );
}

export default Login;
