import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { queryBuilder } from "../../../util/queryBuilder";
import { resultListReq, resultListRes } from "./types";

export const resultApi = createApi({
  reducerPath: "results-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL!}result`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }) as MyBaseQueryFn,
  tagTypes: ["Result"],
  endpoints: (builder) => ({
    getAll: builder.query<resultListRes, resultListReq>({
      query: (query) => ({
        url: `/list${queryBuilder(query)}`,
        method: "GET",
      }),
    }),
  }),
});
