import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { AFFaireListReq, AffaireListRes,AffaireSearch } from "./types";
import {queryBuilder} from '../../../util/queryBuilder'
export const affaireApi = createApi({
    reducerPath: "affaire-api",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL!}affaire`,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    }) as MyBaseQueryFn,
    tagTypes: ["Affaire"],
    endpoints: (builder) => ({
      getAll: builder.query<AffaireListRes, AffaireSearch>({
        query: (query) => ({
          url:`/list${queryBuilder(query)}`,
          method: "GET",
        }),
        providesTags:[{ type: "Affaire", id: "List" }]
      }),
      update : builder.mutation<AffaireListRes, AFFaireListReq>({
        query: (body)=>({
          url : '/update/status',
          method:"PUT",
          body
        }),
        invalidatesTags: [{ type: "Affaire", id: "List" }],
      }),
      updateAffaire : builder.mutation<AffaireListRes, AFFaireListReq>({
        query: (body)=>({
          url : '/update',
          method:"PUT",
          body
        }),
        invalidatesTags: [{ type: "Affaire", id: "List" }],
      })
    }),
  });