import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { VenteListres,VenteSearch } from "./types";
import {queryBuilder} from '../../../util/queryBuilder'


export const venteApi = createApi({
    reducerPath: "vente-api",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL!}vente`,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    }) as MyBaseQueryFn,
    tagTypes: ["Vente"],
    endpoints: (builder) => ({
      getAll: builder.query<VenteListres, VenteSearch>({
        query: (query) => ({
          url: `/list${queryBuilder(query)}`,
          method: "GET",
        }),
      }),
    }),
  });