import { authApi } from "../../store/apis/auth";
import OTPInput from "../Ui/OTPInput";
import useAuth from "../../hooks/useAuth";

interface props {
  email: string;
}
const VerificationCode = ({ email }: props) => {
  const { Login } = useAuth();
  const [verifyCode, { isLoading, error }] = authApi.useVerifyCodeMutation();
  const handleSubmit = (code: number) => {
    verifyCode({ code, email })
      .unwrap()
      .then((res) => {
        Login(res);
      });
  };
  return (
    <div className="from__center">
      <div style={{ width: 370, maxWidth: "90%" }}>
        <OTPInput
          label="Entrer le code"
          onComplete={handleSubmit}
          length={6}
          error={!!error}
          loading={isLoading}
        />
        {error && (
          <p className="opt-error">
            {"status" in error ? error.data.message : ""}
          </p>
        )}
      </div>
    </div>
  );
};

export default VerificationCode;
