import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { QuoteListReq ,QuoteListRes,QuoteSearch,QuoteSend} from "./type";
import {queryBuilder} from '../../../util/queryBuilder'


export const quoteApi = createApi({
    reducerPath: "quote-api",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL!}quote`,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    }) as MyBaseQueryFn,
    tagTypes: ["Quote"],
    endpoints: (builder) => ({
      getAll: builder.query<QuoteListReq,QuoteSearch>({
        query: (query) => ({
          url: `/alldevis/${queryBuilder(query)}`,
          method: "GET",
        }),
        providesTags: [{ type: "Quote", id: "List" }],
        
      }),
      generateDevis : builder.query<Blob,{id:string}>({
        query: ({id}) => ({
            url:`/pdf/${id}`,
            method:"GET",
           responseHandler:(response) =>response.blob()
        })
    }),
    sendDevis : builder.mutation<void,QuoteSend>({
        query:(body)=>({
            url:`/pdf/send`,
            method:"POST",
            body
        }),
        invalidatesTags: [{ type: "Quote", id: "List" }]
    }),
    updateDevis : builder.mutation<QuoteListReq,QuoteListRes>({
        query: (body) => ({ 
            url:'/update/validate',
            method:"PUT",
            body
        }),
        invalidatesTags : [{ type: "Quote", id: "List" }]
    }),
    }),
    
  });