import { resetStateAction } from "../store/actions/resetState";
import { loginRes } from "../store/apis/auth/types";
import { useAppDispatch } from "../store/hooks";
import { setCurrentAuth } from "../store/slices/auth";
import { deleteAuth, storeAuth } from "../util/handleAuthStorage";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const Logout = () => {
    dispatch(resetStateAction());
    deleteAuth();
  };
  const Login = async (res: loginRes) => {
    dispatch(setCurrentAuth(res));
    storeAuth(res);
  };
  return { Logout, Login };
};

export default useAuth;
