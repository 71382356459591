import { Form, Input, message } from "antd";
import { At, Lock, Eye, EyeSlash } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { authApi } from "../store/apis/auth";
interface formFields {
  password: string;
  confirmPassowrd: string;
}

function ChangePassword() {
  const history = useHistory();
  const [updatePassword] = authApi.useUpdatePasswordForgetPasswordMutation();
  const [form] = Form.useForm<formFields>();
  const url = window.location.href.split("token=");
  const token = url[1];
  const handleSubmit = () => {
    form.validateFields().then(({ password, confirmPassowrd }) => {
      if (password === confirmPassowrd) {
        updatePassword({
          token,
          password,
        }).then((res: any) => {
          if (res?.error?.data?.code === 400)
            message.error(res?.error?.data?.message);
          else {
            message.success("Votre Mot de passe a été modifier avec succes");
            window.location.href = process.env.REACT_APP_APP_URL!;
          }
        });
      } else {
        message.error("Verifier votre mot de passe");
      }
    });
  };

  return (
    <div className="from__center">
      <Form className="Form " form={form} layout="vertical">
        <Form.Item
          label="Nouveau Mot de passe"
          name="password"
          rules={[
            { required: true, message: "Mot de passe est obligatoire" },
            // {
            //   pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
            //   message: "Mot de passe est invalid",
            // },
          ]}
        >
          <Input.Password
            prefix={<Lock />}
            placeholder="password"
            className="h-40 w-300"
            iconRender={(visible) => (visible ? <Eye /> : <EyeSlash />)}
          />
        </Form.Item>
        <Form.Item
          label="Confirmer le mot de passe"
          name="confirmPassowrd"
          rules={[
            { required: true, message: "Mot de passe est obligatoire" },
            // {
            //   pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
            //   message: "Mot de passe est invalid",
            // },
          ]}
        >
          <Input.Password
            prefix={<Lock />}
            placeholder="password"
            className="h-40 w-300"
            iconRender={(visible) => (visible ? <Eye /> : <EyeSlash />)}
          />
        </Form.Item>
        <button type="submit" className="btn__form" onClick={handleSubmit}>
          Changer le mot de passe
        </button>
      </Form>
    </div>
  );
}
export default ChangePassword;
