import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { queryBuilder } from "../../../util/queryBuilder";
import {
  loginReq,
  loginRes,
  createUserRes,
  createUserReq,
  getALlUsersRes,
  changeStatusReq,
  successRes,
  updateUserReq,
  changePassword,
  SendEmail,
  updatePassword,
  verifyCodeRes,
  verifyCodeReq,
  Search,
} from "./types";

// user = auth
export const authApi = createApi({
  reducerPath: "auth-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL!}auth`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }) as MyBaseQueryFn,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    login: builder.mutation<loginRes, loginReq>({
      query: (body) => ({
        url: "/login",
        body,
        method: "POST",
      }),
    }),
    verifyCode: builder.mutation<verifyCodeRes, verifyCodeReq>({
      query: (body) => ({
        url: "/verifyCode",
        body,
        method: "POST",
      }),
    }),

    createUser: builder.mutation<createUserRes, createUserReq>({
      query: (body) => ({
        url: "/signup",
        body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Auth", id: "List" }],
    }),
    updateUser: builder.mutation<successRes, updateUserReq>({
      query: (body) => ({
        url: "/update",
        body,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Auth", id: "List" }],
    }),
    changeStatus: builder.mutation<successRes, changeStatusReq>({
      query: (body) => ({
        url: "/update/status",
        body,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Auth", id: "List" }],
    }),
    changePassword: builder.mutation<successRes, SendEmail>({
      query: (body) => ({
        url: "/forget-password-bo",
        body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Auth", id: "List" }],
    }),
    updatePassword: builder.mutation<successRes, changePassword>({
      query: (body) => ({
        url: "/update/password",
        body,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Auth", id: "List" }],
    }),
    updatePasswordForgetPassword: builder.mutation<successRes, updatePassword>({
      query: (body) => ({
        url: "/update/password-bo",
        body,
        method: "PUT",
        headers: { authorization: `bearer ${body?.token}` },
      }),
      invalidatesTags: [{ type: "Auth", id: "List" }],
    }),
    getAllUsers: builder.query<getALlUsersRes, Search>({
      query: (query) => ({
        url: `/list${queryBuilder(query)}`,
        method: "GET",
      }),
      providesTags: [{ type: "Auth", id: "List" }],
    }),
  }),
});
