import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import type { IApiError } from "../interfaces/IApiError";

import { authApi } from "./apis/auth";
import { relanceApi } from "./apis/relance";
import { miscellaneousApi } from "./apis/miscellaneous";
import { clientApi } from "./apis/client";
import { resultApi } from "./apis/results";
import { statisticApi } from "./apis/statistic";
import { logApi } from "./apis/logs";

import authSlice from "./slices/auth";

import { unauthenticatedMiddleware } from "./middleware/unauthenticated";
import { RESET_STATE_ACTION_TYPE } from "./actions/resetState";
import { affaireApi } from "./apis/affaire";
import { venteApi } from "./apis/vente";
import { remiseApi } from "./apis/remise";
import { productApi } from "./apis/product";
import { quoteApi } from "./apis/quote";
import { visiteApi } from "./apis/visite";

const reducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [affaireApi.reducerPath]: affaireApi.reducer,
  [remiseApi.reducerPath]: remiseApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [venteApi.reducerPath]: venteApi.reducer,
  [relanceApi.reducerPath]: relanceApi.reducer,
  [miscellaneousApi.reducerPath]: miscellaneousApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [resultApi.reducerPath]: resultApi.reducer,
  [statisticApi.reducerPath]: statisticApi.reducer,
  [logApi.reducerPath]: logApi.reducer,
  [quoteApi.reducerPath]: quoteApi.reducer,
  [visiteApi.reducerPath]: visiteApi.reducer,
  auth: authSlice.reducer,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = undefined;
  }
  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      unauthenticatedMiddleware,
      authApi.middleware,
      relanceApi.middleware,
      miscellaneousApi.middleware,
      clientApi.middleware,
      resultApi.middleware,
      statisticApi.middleware,
      logApi.middleware,
      affaireApi.middleware,
      venteApi.middleware,
      remiseApi.middleware,
      productApi.middleware,
      quoteApi.middleware,
      visiteApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type MyBaseQueryFn = BaseQueryFn<
  string | FetchArgs,
  unknown,
  IApiError,
  {},
  FetchBaseQueryMeta
>;
