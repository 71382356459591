import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { ProductsListRes } from "./type";

export const productApi = createApi({
    reducerPath: "product-api",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL!}product`,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    }) as MyBaseQueryFn,
    tagTypes: ["Product"],
    endpoints: (builder) => ({
      getAll: builder.query<ProductsListRes, void>({
        query: () => ({
          url: "/list",
          method: "GET",
        }),
        providesTags: [{ type: "Product", id: "List" }],
      }),
    }),
  });