import React, { Suspense, useEffect, useState } from "react";
import Login from "./pages/Login";
import Spin from "./components/Ui/Spin";
import { getAuth } from "./util/handleAuthStorage";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { setCurrentAuth } from "./store/slices/auth";
import ChangePassword from "./pages/ChangePassword";
const Admin = React.lazy(() => import("./pages/Admin/index"));

function App() {
  const [loading, setLoading] = useState(true);
  const auth = useAppSelector((state) => state.auth.auth);
  const dispatche = useAppDispatch();
  const url=window.location.href
  useEffect(() => {
    const res = getAuth();
    if (res) dispatche(setCurrentAuth(res));
    setLoading(false);
  }, []);
  if (loading) return <Spin />;
  else if(url.includes("ChangePassword"))
    return <ChangePassword/>
  return (
    <div className="App">
      <Suspense fallback={<Spin />}>{auth ? <Admin /> : <Login />}</Suspense>
    </div>
  )
}

export default App;
