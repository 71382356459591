import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyBaseQueryFn, RootState } from "../..";
import { relanceListRes } from "./types";

export const miscellaneousApi = createApi({
  reducerPath: "miscellaneous-api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL!}miscellaneous`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }) as MyBaseQueryFn,
  tagTypes: ["Miscellaneous"],
  endpoints: (builder) => ({
    getAll: builder.query<relanceListRes, void>({
      query: () => ({
        url: "/",
        method: "GET",
      }),
      keepUnusedDataFor: 60 * 10,
    }),
  }),
});
